define("discourse/plugins/discourse-ai/discourse/components/ai-bot-header-panel", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "I18n", "discourse/plugins/discourse-ai/discourse/lib/ai-bot-helper", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _I18n, _aiBotHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="bot-panel ai-bot-available-bot-options">
    {{#each this.botNames as |bot|}}
      <DButton
        @translatedTitle={{bot.humanized}}
        @translatedLabel={{bot.humanized}}
        @action={{action "composeMessageWithTargetBot" bot.modelName}}
        class="btn-flat ai-bot-available-bot-content"
      />
    {{/each}}
  </div>
  */
  {
    "id": "Lu8/8vSP",
    "block": "[[[10,0],[14,0,\"bot-panel ai-bot-available-bot-options\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"botNames\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],[[24,0,\"btn-flat ai-bot-available-bot-content\"]],[[\"@translatedTitle\",\"@translatedLabel\",\"@action\"],[[30,1,[\"humanized\"]],[30,1,[\"humanized\"]],[28,[37,3],[[30,0],\"composeMessageWithTargetBot\",[30,1,[\"modelName\"]]],null]]],null],[1,\"\\n\"]],[1]],null],[13]],[\"bot\"],false,[\"each\",\"-track-array\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/components/ai-bot-header-panel.hbs",
    "isStrictMode": false
  });
  class AiBotHeaderPanel extends _component2.default {
    static #_ = dt7948.g(this.prototype, "siteSettings", [_service.inject]);
    #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
    static #_2 = dt7948.g(this.prototype, "composer", [_service.inject]);
    #composer = (dt7948.i(this, "composer"), void 0);
    composeMessageWithTargetBot(target) {
      this.#composeAiBotMessage(target);
    }
    static #_3 = dt7948.n(this.prototype, "composeMessageWithTargetBot", [_object.action]);
    get botNames() {
      return this.enabledBotOptions.map(bot => {
        return {
          humanized: _I18n.default.t(`discourse_ai.ai_bot.bot_names.${bot}`),
          modelName: bot
        };
      });
    }
    get enabledBotOptions() {
      return this.siteSettings.ai_bot_enabled_chat_bots.split("|");
    }
    #composeAiBotMessage(targetBot) {
      this.args.closePanel();
      (0, _aiBotHelper.composeAiBotMessage)(targetBot, this.composer);
    }
  }
  _exports.default = AiBotHeaderPanel;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AiBotHeaderPanel);
});