define("discourse/plugins/discourse-ai/discourse/connectors/search-menu-results-top/ai-quick-search-info", ["exports", "@glimmer/component", "@ember/service", "discourse/lib/search", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _search3, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiQuickSearchInfo extends _component.default {
    static #_ = dt7948.g(this.prototype, "search", [_service.inject]);
    #search = (dt7948.i(this, "search"), void 0);
    static #_2 = dt7948.g(this.prototype, "siteSettings", [_service.inject]);
    #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
    static #_3 = dt7948.g(this.prototype, "quickSearch", [_service.inject]);
    #quickSearch = (dt7948.i(this, "quickSearch"), void 0);
    get termTooShort() {
      // We check the validity again here because the input may have changed
      // since the last time we checked, so we may want to stop showing the error
      const validity1 = !(0, _search3.isValidSearchTerm)(this.search.activeGlobalSearchTerm, this.siteSettings);
      return validity1 && this.quickSearch.invalidTerm && this.search.activeGlobalSearchTerm?.length > 0;
    }
    static #_4 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.termTooShort}}
          <div class="no-results">{{i18n "search.too_short"}}</div>
        {{/if}}
      
    */
    {
      "id": "k6uzZwr4",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"termTooShort\"]],[[[1,\"      \"],[10,0],[14,0,\"no-results\"],[12],[1,[28,[32,0],[\"search.too_short\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/search-menu-results-top/ai-quick-search-info.js",
      "scope": () => [_i18n.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = AiQuickSearchInfo;
});