define("discourse/plugins/discourse-ai/discourse/connectors/after-d-editor/ai-helper-context-menu", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@popperjs/core", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/utilities", "discourse-common/config/environment", "discourse-common/utils/decorators", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper"], function (_exports, _component, _tracking, _object, _service, _core, _ajax, _ajaxError, _utilities, _environment, _decorators, _showAiHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiHelperContextMenu extends _component.default {
    static shouldRender(outletArgs, helper) {
      return (0, _showAiHelper.showComposerAIHelper)(outletArgs, helper, "context_menu");
    }
    static #_ = dt7948.g(this.prototype, "currentUser", [_service.inject]);
    #currentUser = (dt7948.i(this, "currentUser"), void 0);
    static #_2 = dt7948.g(this.prototype, "siteSettings", [_service.inject]);
    #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
    static #_3 = dt7948.g(this.prototype, "modal", [_service.inject]);
    #modal = (dt7948.i(this, "modal"), void 0);
    static #_4 = dt7948.g(this.prototype, "capabilities", [_service.inject]);
    #capabilities = (dt7948.i(this, "capabilities"), void 0);
    static #_5 = dt7948.g(this.prototype, "showContextMenu", [_tracking.tracked], function () {
      return false;
    });
    #showContextMenu = (dt7948.i(this, "showContextMenu"), void 0);
    static #_6 = dt7948.g(this.prototype, "caretCoords", [_tracking.tracked]);
    #caretCoords = (dt7948.i(this, "caretCoords"), void 0);
    static #_7 = dt7948.g(this.prototype, "virtualElement", [_tracking.tracked]);
    #virtualElement = (dt7948.i(this, "virtualElement"), void 0);
    static #_8 = dt7948.g(this.prototype, "selectedText", [_tracking.tracked], function () {
      return "";
    });
    #selectedText = (dt7948.i(this, "selectedText"), void 0);
    static #_9 = dt7948.g(this.prototype, "newSelectedText", [_tracking.tracked]);
    #newSelectedText = (dt7948.i(this, "newSelectedText"), void 0);
    static #_10 = dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    });
    #loading = (dt7948.i(this, "loading"), void 0);
    static #_11 = dt7948.g(this.prototype, "lastUsedOption", [_tracking.tracked], function () {
      return null;
    });
    #lastUsedOption = (dt7948.i(this, "lastUsedOption"), void 0);
    static #_12 = dt7948.g(this.prototype, "showDiffModal", [_tracking.tracked], function () {
      return false;
    });
    #showDiffModal = (dt7948.i(this, "showDiffModal"), void 0);
    static #_13 = dt7948.g(this.prototype, "showThumbnailModal", [_tracking.tracked], function () {
      return false;
    });
    #showThumbnailModal = (dt7948.i(this, "showThumbnailModal"), void 0);
    static #_14 = dt7948.g(this.prototype, "diff", [_tracking.tracked]);
    #diff = (dt7948.i(this, "diff"), void 0);
    static #_15 = dt7948.g(this.prototype, "popperPlacement", [_tracking.tracked], function () {
      return "top-start";
    });
    #popperPlacement = (dt7948.i(this, "popperPlacement"), void 0);
    static #_16 = dt7948.g(this.prototype, "previousMenuState", [_tracking.tracked], function () {
      return null;
    });
    #previousMenuState = (dt7948.i(this, "previousMenuState"), void 0);
    static #_17 = dt7948.g(this.prototype, "customPromptValue", [_tracking.tracked], function () {
      return "";
    });
    #customPromptValue = (dt7948.i(this, "customPromptValue"), void 0);
    static #_18 = dt7948.g(this.prototype, "initialValue", [_tracking.tracked], function () {
      return "";
    });
    #initialValue = (dt7948.i(this, "initialValue"), void 0);
    static #_19 = dt7948.g(this.prototype, "thumbnailSuggestions", [_tracking.tracked], function () {
      return null;
    });
    #thumbnailSuggestions = (dt7948.i(this, "thumbnailSuggestions"), void 0);
    static #_20 = dt7948.g(this.prototype, "selectionRange", [_tracking.tracked], function () {
      return {
        x: 0,
        y: 0
      };
    });
    #selectionRange = (dt7948.i(this, "selectionRange"), void 0);
    static #_21 = dt7948.g(this.prototype, "lastSelectionRange", [_tracking.tracked], function () {
      return null;
    });
    #lastSelectionRange = (dt7948.i(this, "lastSelectionRange"), void 0);
    CONTEXT_MENU_STATES = {
      triggers: "TRIGGERS",
      options: "OPTIONS",
      resets: "RESETS",
      loading: "LOADING",
      review: "REVIEW"
    };
    prompts = [];
    promptTypes = {};
    minSelectionChars = 3;
    static #_22 = dt7948.g(this.prototype, "_menuState", [_tracking.tracked], function () {
      return this.CONTEXT_MENU_STATES.triggers;
    });
    #_menuState = (dt7948.i(this, "_menuState"), void 0);
    static #_23 = dt7948.g(this.prototype, "_popper", [_tracking.tracked]);
    #_popper = (dt7948.i(this, "_popper"), void 0);
    static #_24 = dt7948.g(this.prototype, "_dEditorInput", [_tracking.tracked]);
    #_dEditorInput = (dt7948.i(this, "_dEditorInput"), void 0);
    static #_25 = dt7948.g(this.prototype, "_customPromptInput", [_tracking.tracked]);
    #_customPromptInput = (dt7948.i(this, "_customPromptInput"), void 0);
    static #_26 = dt7948.g(this.prototype, "_contextMenu", [_tracking.tracked]);
    #_contextMenu = (dt7948.i(this, "_contextMenu"), void 0);
    static #_27 = dt7948.g(this.prototype, "_activeAIRequest", [_tracking.tracked], function () {
      return null;
    });
    #_activeAIRequest = (dt7948.i(this, "_activeAIRequest"), void 0);
    willDestroy() {
      super.willDestroy(...arguments);
      document.removeEventListener("selectionchange", this.selectionChanged);
      document.removeEventListener("keydown", this.onKeyDown);
      this._popper?.destroy();
    }
    get menuState() {
      return this._menuState;
    }
    set menuState(newState) {
      this.previousMenuState = this._menuState;
      this._menuState = newState;
    }
    get helperOptions() {
      let prompts = this.currentUser?.ai_helper_prompts;
      prompts = prompts.filter(p => p.location.includes("composer")).filter(p => p.name !== "generate_titles");

      // Find the custom_prompt object and move it to the beginning of the array
      const customPromptIndex = prompts.findIndex(p => p.name === "custom_prompt");
      if (customPromptIndex !== -1) {
        const customPrompt = prompts.splice(customPromptIndex, 1)[0];
        prompts.unshift(customPrompt);
      }
      if (!this._showUserCustomPrompts()) {
        prompts = prompts.filter(p => p.name !== "custom_prompt");
      }
      prompts.forEach(p => {
        this.prompts[p.id] = p;
      });
      this.promptTypes = prompts.reduce((memo, p) => {
        memo[p.name] = p.prompt_type;
        return memo;
      }, {});
      return prompts;
    }
    selectionChanged() {
      if (document.activeElement !== this._dEditorInput) {
        return;
      }
      const canSelect = Boolean(window.getSelection() && document.activeElement && document.activeElement.value);
      this.selectedText = canSelect ? document.activeElement.value.substring(document.activeElement.selectionStart, document.activeElement.selectionEnd) : "";
      this.selectionRange = canSelect ? {
        x: document.activeElement.selectionStart,
        y: document.activeElement.selectionEnd
      } : {
        x: 0,
        y: 0
      };
      if (this.selectedText?.length === 0) {
        this.closeContextMenu();
        return;
      }
      if (this.selectedText?.length < this.minSelectionChars) {
        return;
      }
      this._onSelectionChanged();
    }
    static #_28 = dt7948.n(this.prototype, "selectionChanged", [_decorators.bind]);
    updatePosition() {
      if (!this.showContextMenu) {
        return;
      }
      this.positionContextMenu();
    }
    static #_29 = dt7948.n(this.prototype, "updatePosition", [_decorators.bind]);
    onKeyDown(event) {
      if (event.key === "Escape") {
        return this.closeContextMenu();
      }
      if (event.key === "Backspace" && this.selectedText && this.menuState === this.CONTEXT_MENU_STATES.triggers) {
        return this.closeContextMenu();
      }
    }
    static #_30 = dt7948.n(this.prototype, "onKeyDown", [_decorators.bind]);
    _onSelectionChanged() {
      this.positionContextMenu();
      this.showContextMenu = true;
    }
    static #_31 = dt7948.n(this.prototype, "_onSelectionChanged", [(0, _decorators.debounce)(_environment.INPUT_DELAY)]);
    generateGetBoundingClientRect() {
      let width = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      let height = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      let x = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      let y = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
      return () => ({
        width,
        height,
        top: y,
        right: x,
        bottom: y,
        left: x
      });
    }
    get canCloseContextMenu() {
      if (document.activeElement === this._customPromptInput) {
        return false;
      }
      if (this.loading && this._activeAIRequest !== null) {
        return false;
      }
      if (this.menuState === this.CONTEXT_MENU_STATES.review) {
        return false;
      }
      return true;
    }
    closeContextMenu() {
      if (!this.canCloseContextMenu) {
        return;
      }
      this.showContextMenu = false;
      this.menuState = this.CONTEXT_MENU_STATES.triggers;
      this.customPromptValue = "";
    }
    _updateSuggestedByAI(data) {
      this.newSelectedText = data.suggestions[0];
      if (data.diff) {
        this.diff = data.diff;
      }
      this._insertAt(this.selectionRange.x, this.selectionRange.y, this.newSelectedText);
      this.menuState = this.CONTEXT_MENU_STATES.review;
    }
    _insertAt(start, end, text) {
      this._dEditorInput.setSelectionRange(start, end);
      this._dEditorInput.focus();
      document.execCommand("insertText", false, text);
    }
    _toggleLoadingState(loading) {
      if (loading) {
        this._dEditorInput.classList.add("loading");
        return this.loading = true;
      }
      this._dEditorInput.classList.remove("loading");
      return this.loading = false;
    }
    _showUserCustomPrompts() {
      return this.currentUser?.can_use_custom_prompts;
    }
    handleBoundaries() {
      const textAreaWrapper = document.querySelector(".d-editor-textarea-wrapper").getBoundingClientRect();
      const buttonBar = document.querySelector(".d-editor-button-bar").getBoundingClientRect();
      const boundaryElement = {
        top: buttonBar.bottom,
        bottom: textAreaWrapper.bottom
      };
      const contextMenuRect = this._contextMenu.getBoundingClientRect();

      // Hide context menu if it's scrolled out of bounds:
      if (contextMenuRect.top < boundaryElement.top) {
        this._contextMenu.classList.add("out-of-bounds");
      } else if (contextMenuRect.bottom > boundaryElement.bottom) {
        this._contextMenu.classList.add("out-of-bounds");
      } else {
        this._contextMenu.classList.remove("out-of-bounds");
      }

      // Position context menu at based on if interfering with button bar
      if (this.caretCoords.y - contextMenuRect.height < boundaryElement.top) {
        this.popperPlacement = "bottom-start";
      } else {
        this.popperPlacement = "top-start";
      }
    }
    positionContextMenu() {
      this._contextMenu = document.querySelector(".ai-helper-context-menu");
      if (!this._dEditorInput || !this._contextMenu) {
        return;
      }
      this.caretCoords = (0, _utilities.getCaretPosition)(this._dEditorInput, {
        pos: (0, _utilities.caretPosition)(this._dEditorInput)
      });

      // prevent overflow of context menu outside of editor
      this.handleBoundaries();
      this.virtualElement = {
        getBoundingClientRect: this.generateGetBoundingClientRect(this._contextMenu.clientWidth, this._contextMenu.clientHeight, this.caretCoords.x, this.caretCoords.y)
      };
      this._popper = (0, _core.createPopper)(this.virtualElement, this._contextMenu, {
        placement: this.popperPlacement,
        modifiers: [{
          name: "offset",
          options: {
            offset: [10, 0]
          }
        }]
      });
    }
    static #_32 = dt7948.n(this.prototype, "positionContextMenu", [_decorators.afterRender]);
    setupContextMenu() {
      document.addEventListener("selectionchange", this.selectionChanged);
      document.addEventListener("keydown", this.onKeyDown);
      this._dEditorInput = document.querySelector(".d-editor-input");
      if (this._dEditorInput) {
        this._dEditorInput.addEventListener("scroll", this.updatePosition);
      }
    }
    static #_33 = dt7948.n(this.prototype, "setupContextMenu", [_object.action]);
    setupCustomPrompt() {
      this._customPromptInput = document.querySelector(".ai-custom-prompt__input");
      this._customPromptInput.focus();
    }
    static #_34 = dt7948.n(this.prototype, "setupCustomPrompt", [_object.action]);
    toggleAiHelperOptions() {
      this.menuState = this.CONTEXT_MENU_STATES.options;
    }
    static #_35 = dt7948.n(this.prototype, "toggleAiHelperOptions", [_object.action]);
    undoAIAction() {
      if (this.capabilities.isFirefox) {
        // execCommand("undo") is no not supported in Firefox so we insert old text at range
        // we also need to calculate the length diffrence between the old and new text
        const lengthDifference = this.selectedText.length - this.initialValue.length;
        const end = this.lastSelectionRange.y - lengthDifference;
        this._insertAt(this.lastSelectionRange.x, end, this.initialValue);
      } else {
        document.execCommand("undo", false, null);
      }

      // context menu is prevented from closing when in review state
      // so we change to reset state quickly before closing
      this.menuState = this.CONTEXT_MENU_STATES.resets;
      this.closeContextMenu();
    }
    static #_36 = dt7948.n(this.prototype, "undoAIAction", [_object.action]);
    async updateSelected(option) {
      this._toggleLoadingState(true);
      this.lastUsedOption = option;
      this.menuState = this.CONTEXT_MENU_STATES.loading;
      this.initialValue = this.selectedText;
      this.lastSelectionRange = this.selectionRange;
      this._activeAIRequest = (0, _ajax.ajax)("/discourse-ai/ai-helper/suggest", {
        method: "POST",
        data: {
          mode: option.id,
          text: this.selectedText,
          custom_prompt: this.customPromptValue
        }
      });
      this._activeAIRequest.then(data => {
        // resets the values if new suggestion is started:
        this.diff = null;
        this.newSelectedText = null;
        this.thumbnailSuggestions = null;
        if (option.name === "illustrate_post") {
          this._toggleLoadingState(false);
          this.closeContextMenu();
          this.showThumbnailModal = true;
          this.thumbnailSuggestions = data.thumbnails;
        } else {
          this._updateSuggestedByAI(data);
        }
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this._toggleLoadingState(false);
      });
      return this._activeAIRequest;
    }
    static #_37 = dt7948.n(this.prototype, "updateSelected", [_object.action]);
    viewChanges() {
      this.showDiffModal = true;
    }
    static #_38 = dt7948.n(this.prototype, "viewChanges", [_object.action]);
    confirmChanges() {
      this.menuState = this.CONTEXT_MENU_STATES.resets;
    }
    static #_39 = dt7948.n(this.prototype, "confirmChanges", [_object.action]);
    cancelAIAction() {
      if (this._activeAIRequest) {
        this._activeAIRequest.abort();
        this._activeAIRequest = null;
        this._toggleLoadingState(false);
        this.closeContextMenu();
      }
    }
    static #_40 = dt7948.n(this.prototype, "cancelAIAction", [_object.action]);
    togglePreviousMenu() {
      this.menuState = this.previousMenuState;
    }
    static #_41 = dt7948.n(this.prototype, "togglePreviousMenu", [_object.action]);
  }
  _exports.default = AiHelperContextMenu;
});