define("discourse/plugins/discourse-ai/discourse/connectors/composer-fields/persona-selector", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse/lib/key-value-store", "select-kit/components/dropdown-select-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _keyValueStore, _dropdownSelectBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function isBotMessage(composer1, currentUser1) {
    if (composer1 && composer1.targetRecipients && currentUser1.ai_enabled_chat_bots) {
      const reciepients1 = composer1.targetRecipients.split(",");
      return currentUser1.ai_enabled_chat_bots.any(bot1 => reciepients1.any(username1 => username1 === bot1.username));
    }
    return false;
  }
  class BotSelector extends _component.default {
    static shouldRender(args1, container1) {
      return container1?.currentUser?.ai_enabled_personas && isBotMessage(args1.model, container1.currentUser);
    }
    static #_ = dt7948.g(this.prototype, "currentUser", [_service.inject]);
    #currentUser = (dt7948.i(this, "currentUser"), void 0);
    STORE_NAMESPACE = "discourse_ai_persona_selector_";
    preferredPersonaStore = new _keyValueStore.default(this.STORE_NAMESPACE);
    constructor() {
      super(...arguments);
      if (this.botOptions && this.composer) {
        let personaId1 = this.preferredPersonaStore.getObject("id");
        this._value = this.botOptions[0].id;
        if (personaId1) {
          personaId1 = parseInt(personaId1, 10);
          if (this.botOptions.any(bot1 => bot1.id === personaId1)) {
            this._value = personaId1;
          }
        }
        this.composer.metaData = {
          ai_persona_id: this._value
        };
      }
    }
    get composer() {
      return this.args?.outletArgs?.model;
    }
    get botOptions() {
      if (this.currentUser.ai_enabled_personas) {
        return this.currentUser.ai_enabled_personas.map(persona1 => {
          return {
            id: persona1.id,
            name: persona1.name,
            description: persona1.description
          };
        });
      }
    }
    get filterable() {
      return this.botOptions.length > 4;
    }
    get value() {
      return this._value;
    }
    set value(newValue1) {
      this._value = newValue1;
      this.preferredPersonaStore.setObject({
        key: "id",
        value: newValue1
      });
      this.composer.metaData = {
        ai_persona_id: newValue1
      };
    }
    static #_2 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="gpt-persona">
          <DropdownSelectBox
            class="persona-selector__dropdown"
            @value={{this.value}}
            @content={{this.botOptions}}
            @options={{hash icon="robot" filterable=this.filterable}}
          />
        </div>
      
    */
    {
      "id": "TcbpLBxH",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"gpt-persona\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"persona-selector__dropdown\"]],[[\"@value\",\"@content\",\"@options\"],[[30,0,[\"value\"]],[30,0,[\"botOptions\"]],[28,[32,1],null,[[\"icon\",\"filterable\"],[\"robot\",[30,0,[\"filterable\"]]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/composer-fields/persona-selector.js",
      "scope": () => [_dropdownSelectBox.default, _helper.hash],
      "isStrictMode": true
    }), this);
  }
  _exports.default = BotSelector;
});