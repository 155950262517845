define("discourse/plugins/discourse-ai/discourse/components/modal/thumbnail-suggestions", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse-common/helpers/i18n", "discourse/plugins/discourse-ai/discourse/components/thumbnail-suggestion-item", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _dButton, _dModal, _dModalCancel, _i18n, _thumbnailSuggestionItem, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ThumbnailSuggestions extends _component.default {
    static #_ = dt7948.g(this.prototype, "selectedImages", [_tracking.tracked], function () {
      return [];
    });
    #selectedImages = (dt7948.i(this, "selectedImages"), void 0);
    get isDisabled() {
      return this.selectedImages.length === 0;
    }
    addSelection(selection1) {
      const thumbnailMarkdown1 = `![${selection1.original_filename}|${selection1.width}x${selection1.height}](${selection1.short_url})`;
      this.selectedImages = [...this.selectedImages, thumbnailMarkdown1];
    }
    static #_2 = dt7948.n(this.prototype, "addSelection", [_object.action]);
    removeSelection(selection1) {
      const thumbnailMarkdown1 = `![${selection1.original_filename}|${selection1.width}x${selection1.height}](${selection1.short_url})`;
      this.selectedImages = this.selectedImages.filter(thumbnail1 => {
        if (thumbnail1 !== thumbnailMarkdown1) {
          return thumbnail1;
        }
      });
    }
    static #_3 = dt7948.n(this.prototype, "removeSelection", [_object.action]);
    appendSelectedImages() {
      const imageMarkdown1 = "\n\n" + this.selectedImages.join("\n");
      const dEditorInput1 = document.querySelector(".d-editor-input");
      dEditorInput1.setSelectionRange(dEditorInput1.value.length, dEditorInput1.value.length);
      dEditorInput1.focus();
      document.execCommand("insertText", false, imageMarkdown1);
      this.args.closeModal();
    }
    static #_4 = dt7948.n(this.prototype, "appendSelectedImages", [_object.action]);
    static #_5 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          class="thumbnail-suggestions-modal"
          @title={{i18n "discourse_ai.ai_helper.thumbnail_suggestions.title"}}
          @closeModal={{@closeModal}}
        >
          <:body>
            <div class="ai-thumbnail-suggestions">
              {{#each @thumbnails as |thumbnail|}}
                <ThumbnailSuggestionItem
                  @thumbnail={{thumbnail}}
                  @addSelection={{this.addSelection}}
                  @removeSelection={{this.removeSelection}}
                />
              {{/each}}
            </div>
          </:body>
    
          <:footer>
            <DButton
              @action={{this.appendSelectedImages}}
              @label="save"
              @disabled={{this.isDisabled}}
              class="btn-primary create"
            />
            <DModalCancel @close={{@closeModal}} />
          </:footer>
        </DModal>
      
    */
    {
      "id": "rVPkxzzs",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"thumbnail-suggestions-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_ai.ai_helper.thumbnail_suggestions.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"ai-thumbnail-suggestions\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,2]],null]],null],null,[[[1,\"            \"],[8,[32,2],null,[[\"@thumbnail\",\"@addSelection\",\"@removeSelection\"],[[30,3],[30,0,[\"addSelection\"]],[30,0,[\"removeSelection\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,0,\"btn-primary create\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"appendSelectedImages\"]],\"save\",[30,0,[\"isDisabled\"]]]],null],[1,\"\\n        \"],[8,[32,4],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@thumbnails\",\"thumbnail\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/thumbnail-suggestions.js",
      "scope": () => [_dModal.default, _i18n.default, _thumbnailSuggestionItem.default, _dButton.default, _dModalCancel.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = ThumbnailSuggestions;
});