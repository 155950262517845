define("discourse/plugins/discourse-ai/discourse/components/rag-upload-progress", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse-common/helpers/d-icon", "discourse-common/utils/decorators", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _service, _dIcon, _decorators, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RagUploadProgress extends _component.default {
    static #_ = dt7948.g(this.prototype, "messageBus", [_service.inject]);
    #messageBus = (dt7948.i(this, "messageBus"), void 0);
    static #_2 = dt7948.g(this.prototype, "updatedProgress", [_tracking.tracked], function () {
      return null;
    });
    #updatedProgress = (dt7948.i(this, "updatedProgress"), void 0);
    willDestroy() {
      super.willDestroy(...arguments);
      this.messageBus.unsubscribe(`/discourse-ai/ai-persona-rag/${this.args.upload.id}`);
    }
    trackProgress() {
      this.messageBus.subscribe(`/discourse-ai/ai-persona-rag/${this.args.upload.id}`, this.onIndexingUpdate);
    }
    static #_3 = dt7948.n(this.prototype, "trackProgress", [_object.action]);
    onIndexingUpdate(data1) {
      // Order not guaranteed. Discard old updates.
      if (!this.updatedProgress || data1.total === 0 || this.updatedProgress.left > data1.left) {
        this.updatedProgress = data1;
      }
    }
    static #_4 = dt7948.n(this.prototype, "onIndexingUpdate", [_decorators.bind]);
    get calculateProgress() {
      if (this.progress.total === 0) {
        return 0;
      }
      return Math.ceil(this.progress.indexed * 100 / this.progress.total);
    }
    get fullyIndexed() {
      return this.progress && this.progress.total !== 0 && this.progress.left === 0;
    }
    get progress() {
      if (this.updatedProgress) {
        return this.updatedProgress;
      } else if (this.args.ragIndexingStatuses) {
        return this.args.ragIndexingStatuses[this.args.upload.id];
      } else {
        return [];
      }
    }
    static #_5 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <td
          class="persona-rag-uploader__upload-status"
          {{didInsert this.trackProgress}}
        >
          {{#if this.progress}}
            {{#if this.fullyIndexed}}
              <span class="indexed">
                {{icon "check"}}
                {{I18n.t "discourse_ai.ai_persona.uploads.indexed"}}
              </span>
            {{else}}
              <span class="indexing">
                {{icon "robot"}}
                {{I18n.t "discourse_ai.ai_persona.uploads.indexing"}}
                {{this.calculateProgress}}%
              </span>
            {{/if}}
          {{else}}
            <span class="uploaded">{{I18n.t
                "discourse_ai.ai_persona.uploads.uploaded"
              }}</span>
          {{/if}}
        </td>
      
    */
    {
      "id": "otL3+nu2",
      "block": "[[[1,\"\\n    \"],[11,\"td\"],[24,0,\"persona-rag-uploader__upload-status\"],[4,[32,0],[[30,0,[\"trackProgress\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"progress\"]],[[[41,[30,0,[\"fullyIndexed\"]],[[[1,\"          \"],[10,1],[14,0,\"indexed\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"check\"],null]],[1,\"\\n            \"],[1,[28,[32,2,[\"t\"]],[\"discourse_ai.ai_persona.uploads.indexed\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[14,0,\"indexing\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"robot\"],null]],[1,\"\\n            \"],[1,[28,[32,2,[\"t\"]],[\"discourse_ai.ai_persona.uploads.indexing\"],null]],[1,\"\\n            \"],[1,[30,0,[\"calculateProgress\"]]],[1,\"%\\n          \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"        \"],[10,1],[14,0,\"uploaded\"],[12],[1,[28,[32,2,[\"t\"]],[\"discourse_ai.ai_persona.uploads.uploaded\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/rag-upload-progress.js",
      "scope": () => [_didInsert.default, _dIcon.default, _discourseI18n.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = RagUploadProgress;
});