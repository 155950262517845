define("discourse/plugins/discourse-ai/discourse/connectors/admin-dashboard-tabs-after/admin-sentiment-dashbboard", ["exports", "@glimmer/component", "@ember/routing", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AISentimentDashboard extends _component.default {
    static shouldRender(_outletArgs1, helper1) {
      return helper1.siteSettings.ai_sentiment_enabled;
    }
    static #_ = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li class="navigation-item sentiment">
          <LinkTo @route="admin.dashboardSentiment" class="navigation-link">
            {{i18n "discourse_ai.sentiments.dashboard.title"}}
          </LinkTo>
        </li>
      
    */
    {
      "id": "aCrw9598",
      "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,0,\"navigation-item sentiment\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"navigation-link\"]],[[\"@route\"],[\"admin.dashboardSentiment\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"discourse_ai.sentiments.dashboard.title\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/admin-dashboard-tabs-after/admin-sentiment-dashbboard.js",
      "scope": () => [_routing.LinkTo, _i18n.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = AISentimentDashboard;
});