define("discourse/plugins/discourse-ai/discourse/components/modal/diff-modal", ["exports", "@glimmer/component", "@ember/object", "@ember/template", "discourse/components/d-button", "discourse/components/d-modal", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _template2, _dButton, _dModal, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ModalDiffModal extends _component.default {
    triggerConfirmChanges() {
      this.args.closeModal();
      this.args.confirm();
    }
    static #_ = dt7948.n(this.prototype, "triggerConfirmChanges", [_object.action]);
    triggerRevertChanges() {
      this.args.closeModal();
      this.args.revert();
    }
    static #_2 = dt7948.n(this.prototype, "triggerRevertChanges", [_object.action]);
    static #_3 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          class="composer-ai-helper-modal"
          @title={{i18n "discourse_ai.ai_helper.context_menu.changes"}}
          @closeModal={{@closeModal}}
        >
          <:body>
            {{#if @diff}}
              {{htmlSafe @diff}}
            {{else}}
              <div class="composer-ai-helper-modal__old-value">
                {{@oldValue}}
              </div>
    
              <div class="composer-ai-helper-modal__new-value">
                {{@newValue}}
              </div>
            {{/if}}
          </:body>
    
          <:footer>
            <DButton
              class="btn-primary confirm"
              @action={{this.triggerConfirmChanges}}
              @label="discourse_ai.ai_helper.context_menu.confirm"
            />
            <DButton
              class="btn-flat"
              @action={{this.triggerRevertChanges}}
              @label="discourse_ai.ai_helper.context_menu.revert"
            />
          </:footer>
        </DModal>
      
    */
    {
      "id": "tqHStVL1",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"composer-ai-helper-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_ai.ai_helper.context_menu.changes\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,\"          \"],[1,[28,[32,2],[[30,2]],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,0],[14,0,\"composer-ai-helper-modal__old-value\"],[12],[1,\"\\n            \"],[1,[30,3]],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[10,0],[14,0,\"composer-ai-helper-modal__new-value\"],[12],[1,\"\\n            \"],[1,[30,4]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,0,\"btn-primary confirm\"]],[[\"@action\",\"@label\"],[[30,0,[\"triggerConfirmChanges\"]],\"discourse_ai.ai_helper.context_menu.confirm\"]],null],[1,\"\\n        \"],[8,[32,3],[[24,0,\"btn-flat\"]],[[\"@action\",\"@label\"],[[30,0,[\"triggerRevertChanges\"]],\"discourse_ai.ai_helper.context_menu.revert\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@diff\",\"@oldValue\",\"@newValue\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/diff-modal.js",
      "scope": () => [_dModal.default, _i18n.default, _template2.htmlSafe, _dButton.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = ModalDiffModal;
});