define("discourse/plugins/discourse-ai/discourse/connectors/search-menu-initial-options/ai-quick-semantic-search", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/search-menu/results/assistant-item", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/search", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _assistantItem, _ajax, _ajaxError, _search3, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiQuickSemanticSearch extends _component.default {
    static shouldRender(_args1, _ref) {
      let {
        siteSettings: siteSettings1
      } = _ref;
      return siteSettings1.ai_embeddings_semantic_quick_search_enabled;
    }
    static #_ = dt7948.g(this.prototype, "search", [_service.inject]);
    #search = (dt7948.i(this, "search"), void 0);
    static #_2 = dt7948.g(this.prototype, "quickSearch", [_service.inject]);
    #quickSearch = (dt7948.i(this, "quickSearch"), void 0);
    static #_3 = dt7948.g(this.prototype, "siteSettings", [_service.inject]);
    #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
    async searchTermChanged() {
      if (!this.search.activeGlobalSearchTerm) {
        this.search.noResults = false;
        this.search.results = {};
        this.quickSearch.loading = false;
        this.quickSearch.invalidTerm = false;
      } else if (!(0, _search3.isValidSearchTerm)(this.search.activeGlobalSearchTerm, this.siteSettings)) {
        this.search.noResults = true;
        this.search.results = {};
        this.quickSearch.loading = false;
        this.quickSearch.invalidTerm = true;
        return;
      } else {
        await this.performSearch();
      }
    }
    static #_4 = dt7948.n(this.prototype, "searchTermChanged", [_object.action]);
    async performSearch() {
      this.quickSearch.loading = true;
      this.quickSearch.invalidTerm = false;
      try {
        const results1 = await (0, _ajax.ajax)(`/discourse-ai/embeddings/quick-search`, {
          data: {
            q: this.search.activeGlobalSearchTerm
          }
        });
        const searchResults1 = await (0, _search3.translateResults)(results1);
        if (searchResults1) {
          this.search.noResults = results1.resultTypes.length === 0;
          this.search.results = searchResults1;
        }
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      } finally {
        this.quickSearch.loading = false;
      }
    }
    static #_5 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{yield}}
    
        {{#if this.search.activeGlobalSearchTerm}}
          <AssistantItem
            @suffix={{i18n "discourse_ai.embeddings.quick_search.suffix"}}
            @icon="discourse-sparkles"
            @closeSearchMenu={{@closeSearchMenu}}
            @searchTermChanged={{this.searchTermChanged}}
            @suggestionKeyword={{@suggestionKeyword}}
          />
        {{/if}}
      
    */
    {
      "id": "xqHDbd5x",
      "block": "[[[1,\"\\n    \"],[18,3,null],[1,\"\\n\\n\"],[41,[30,0,[\"search\",\"activeGlobalSearchTerm\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@suffix\",\"@icon\",\"@closeSearchMenu\",\"@searchTermChanged\",\"@suggestionKeyword\"],[[28,[32,1],[\"discourse_ai.embeddings.quick_search.suffix\"],null],\"discourse-sparkles\",[30,1],[30,0,[\"searchTermChanged\"]],[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@closeSearchMenu\",\"@suggestionKeyword\",\"&default\"],false,[\"yield\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/search-menu-initial-options/ai-quick-semantic-search.js",
      "scope": () => [_assistantItem.default, _i18n.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = AiQuickSemanticSearch;
});