define("discourse/plugins/discourse-ai/discourse/components/ai-bot-header-icon", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse-common/helpers/i18n", "float-kit/components/d-menu", "discourse/plugins/discourse-ai/discourse/lib/ai-bot-helper", "discourse/plugins/discourse-ai/discourse/components/ai-bot-header-panel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _truthHelpers, _dButton, _i18n, _dMenu, _aiBotHelper, _aiBotHeaderPanel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiBotHeaderIcon extends _component.default {
    static #_ = dt7948.g(this.prototype, "siteSettings", [_service.service]);
    #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
    static #_2 = dt7948.g(this.prototype, "composer", [_service.service]);
    #composer = (dt7948.i(this, "composer"), void 0);
    get bots() {
      return this.siteSettings.ai_bot_add_to_header ? this.siteSettings.ai_bot_enabled_chat_bots.split("|").filter(Boolean) : [];
    }
    compose() {
      (0, _aiBotHelper.composeAiBotMessage)(this.bots[0], this.composer);
    }
    static #_3 = dt7948.n(this.prototype, "compose", [_object.action]);
    static #_4 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (gt this.bots.length 0)}}
          <li>
            {{#if (gt this.bots.length 1)}}
              <DMenu
                @icon="robot"
                @title={{i18n "discourse_ai.ai_bot.shortcut_title"}}
                class="ai-bot-button icon btn-flat"
              >
                <:content as |args|>
                  <AiBotHeaderPanel @closePanel={{args.close}} />
                </:content>
              </DMenu>
            {{else}}
              <DButton
                @icon="robot"
                @title={{i18n "discourse_ai.ai_bot.shortcut_title"}}
                class="ai-bot-button icon btn-flat"
                @action={{this.compose}}
              />
            {{/if}}
          </li>
        {{/if}}
      
    */
    {
      "id": "LIQczarb",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"bots\",\"length\"]],0],null],[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"bots\",\"length\"]],1],null],[[[1,\"          \"],[8,[32,1],[[24,0,\"ai-bot-button icon btn-flat\"]],[[\"@icon\",\"@title\"],[\"robot\",[28,[32,2],[\"discourse_ai.ai_bot.shortcut_title\"],null]]],[[\"content\"],[[[[1,\"\\n              \"],[8,[32,3],null,[[\"@closePanel\"],[[30,1,[\"close\"]]]],null],[1,\"\\n            \"]],[1]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,4],[[24,0,\"ai-bot-button icon btn-flat\"]],[[\"@icon\",\"@title\",\"@action\"],[\"robot\",[28,[32,2],[\"discourse_ai.ai_bot.shortcut_title\"],null],[30,0,[\"compose\"]]]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"args\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-bot-header-icon.js",
      "scope": () => [_truthHelpers.gt, _dMenu.default, _i18n.default, _aiBotHeaderPanel.default, _dButton.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = AiBotHeaderIcon;
});