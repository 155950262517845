define("discourse/plugins/discourse-ai/discourse/components/ai-persona-command-options", ["exports", "@glimmer/component", "discourse-i18n", "discourse/plugins/discourse-ai/discourse/components/ai-persona-command-option-editor", "@ember/component", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _aiPersonaCommandOptionEditor, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiPersonaCommandOptions extends _component.default {
    get showCommandOptions() {
      const allCommands1 = this.args.allCommands;
      if (!allCommands1) {
        return false;
      }
      return this.commandNames.any(command1 => allCommands1.find(c1 => c1.id === command1)?.options);
    }
    get commandNames() {
      if (!this.args.commands) {
        return [];
      }
      return this.args.commands.map(command1 => {
        if (typeof command1 === "string") {
          return command1;
        } else {
          return command1[0];
        }
      });
    }
    get commandOptions() {
      if (!this.args.commands) {
        return [];
      }
      const allCommands1 = this.args.allCommands;
      if (!allCommands1) {
        return [];
      }
      const options1 = [];
      this.commandNames.forEach(commandId1 => {
        const command1 = allCommands1.find(c1 => c1.id === commandId1);
        const commandName1 = command1?.name;
        const commandOptions1 = command1?.options;
        if (commandOptions1) {
          const mappedOptions1 = Object.keys(commandOptions1).map(key1 => {
            const value1 = this.args.persona.getCommandOption(commandId1, key1);
            return Object.assign({}, commandOptions1[key1], {
              id: key1,
              value: value1
            });
          });
          options1.push({
            commandName: commandName1,
            options: mappedOptions1
          });
        }
      });
      return options1;
    }
    static #_ = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showCommandOptions}}
          <div class="control-group">
            <label>{{I18n.t "discourse_ai.ai_persona.command_options"}}</label>
            <div>
              {{#each this.commandOptions as |commandOption|}}
                <div class="ai-persona-editor__command-options">
                  <div class="ai-persona-editor__command-options-name">
                    {{commandOption.commandName}}
                  </div>
                  <div class="ai-persona-editor__command-option-options">
                    {{#each commandOption.options as |option|}}
                      <AiPersonaCommandOptionEditor @option={{option}} />
                    {{/each}}
                  </div>
                </div>
              {{/each}}
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "4ZSHKwgK",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showCommandOptions\"]],[[[1,\"      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,[28,[32,0,[\"t\"]],[\"discourse_ai.ai_persona.command_options\"],null]],[13],[1,\"\\n        \"],[10,0],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"commandOptions\"]]],null]],null],null,[[[1,\"            \"],[10,0],[14,0,\"ai-persona-editor__command-options\"],[12],[1,\"\\n              \"],[10,0],[14,0,\"ai-persona-editor__command-options-name\"],[12],[1,\"\\n                \"],[1,[30,1,[\"commandName\"]]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,0],[14,0,\"ai-persona-editor__command-option-options\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1,[\"options\"]]],null]],null],null,[[[1,\"                  \"],[8,[32,1],null,[[\"@option\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"commandOption\",\"option\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-persona-command-options.js",
      "scope": () => [_discourseI18n.default, _aiPersonaCommandOptionEditor.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = AiPersonaCommandOptions;
});