define("discourse/plugins/discourse-ai/discourse/components/modal/debug-ai-modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/template", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/utilities", "discourse-common/helpers/i18n", "discourse-common/lib/later", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _runloop, _template2, _dButton, _dModal, _ajax, _utilities, _i18n, _later, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DebugAiModal extends _component.default {
    static #_ = dt7948.g(this.prototype, "info", [_tracking.tracked], function () {
      return null;
    });
    #info = (dt7948.i(this, "info"), void 0);
    static #_2 = dt7948.g(this.prototype, "justCopiedText", [_tracking.tracked], function () {
      return "";
    });
    #justCopiedText = (dt7948.i(this, "justCopiedText"), void 0);
    constructor() {
      super(...arguments);
      (0, _runloop.next)(() => {
        this.loadApiRequestInfo();
      });
    }
    get htmlContext() {
      if (!this.info) {
        return "";
      }
      let parsed1;
      try {
        parsed1 = JSON.parse(this.info.raw_request_payload);
      } catch (e1) {
        return this.info.raw_request_payload;
      }
      return (0, _template2.htmlSafe)(this.jsonToHtml(parsed1));
    }
    jsonToHtml(json1) {
      let html1 = "<ul>";
      for (let key1 in json1) {
        if (!json1.hasOwnProperty(key1)) {
          continue;
        }
        html1 += "<li>";
        if (typeof json1[key1] === "object" && Array.isArray(json1[key1])) {
          html1 += `<strong>${(0, _utilities.escapeExpression)(key1)}:</strong> ${this.jsonToHtml(json1[key1])}`;
        } else if (typeof json1[key1] === "object") {
          html1 += `<strong>${(0, _utilities.escapeExpression)(key1)}:</strong> <ul><li>${this.jsonToHtml(json1[key1])}</li></ul>`;
        } else {
          let value1 = json1[key1];
          if (typeof value1 === "string") {
            value1 = (0, _utilities.escapeExpression)(value1);
            value1 = value1.replace(/\n/g, "<br>");
          }
          html1 += `<strong>${(0, _utilities.escapeExpression)(key1)}:</strong> ${value1}`;
        }
        html1 += "</li>";
      }
      html1 += "</ul>";
      return html1;
    }
    copyRequest() {
      this.copy(this.info.raw_request_payload);
    }
    static #_3 = dt7948.n(this.prototype, "copyRequest", [_object.action]);
    copyResponse() {
      this.copy(this.info.raw_response_payload);
    }
    static #_4 = dt7948.n(this.prototype, "copyResponse", [_object.action]);
    copy(text1) {
      (0, _utilities.clipboardCopy)(text1);
      this.justCopiedText = _discourseI18n.default.t("discourse_ai.ai_bot.conversation_shared");
      (0, _later.default)(() => {
        this.justCopiedText = "";
      }, 2000);
    }
    loadApiRequestInfo() {
      (0, _ajax.ajax)(`/discourse-ai/ai-bot/post/${this.args.model.id}/show-debug-info.json`).then(result1 => {
        this.info = result1;
      });
    }
    static #_5 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          class="ai-debug-modal"
          @title={{i18n "discourse_ai.ai_bot.debug_ai_modal.title"}}
          @closeModal={{@closeModal}}
        >
          <:body>
            <div class="ai-debug-modal__tokens">
              <span>
                {{i18n "discourse_ai.ai_bot.debug_ai_modal.request_tokens"}}
                {{this.info.request_tokens}}
              </span>
              <span>
                {{i18n "discourse_ai.ai_bot.debug_ai_modal.response_tokens"}}
                {{this.info.response_tokens}}
              </span>
            </div>
            <div class="debug-ai-modal__preview">
              {{this.htmlContext}}
            </div>
          </:body>
    
          <:footer>
            <DButton
              class="btn confirm"
              @icon="copy"
              @action={{this.copyRequest}}
              @label="discourse_ai.ai_bot.debug_ai_modal.copy_request"
            />
            <DButton
              class="btn confirm"
              @icon="copy"
              @action={{this.copyResponse}}
              @label="discourse_ai.ai_bot.debug_ai_modal.copy_response"
            />
            <span class="ai-debut-modal__just-copied">{{this.justCopiedText}}</span>
          </:footer>
        </DModal>
      
    */
    {
      "id": "oaEZryd9",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"ai-debug-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_ai.ai_bot.debug_ai_modal.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"ai-debug-modal__tokens\"],[12],[1,\"\\n          \"],[10,1],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"discourse_ai.ai_bot.debug_ai_modal.request_tokens\"],null]],[1,\"\\n            \"],[1,[30,0,[\"info\",\"request_tokens\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,1],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"discourse_ai.ai_bot.debug_ai_modal.response_tokens\"],null]],[1,\"\\n            \"],[1,[30,0,[\"info\",\"response_tokens\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"debug-ai-modal__preview\"],[12],[1,\"\\n          \"],[1,[30,0,[\"htmlContext\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn confirm\"]],[[\"@icon\",\"@action\",\"@label\"],[\"copy\",[30,0,[\"copyRequest\"]],\"discourse_ai.ai_bot.debug_ai_modal.copy_request\"]],null],[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn confirm\"]],[[\"@icon\",\"@action\",\"@label\"],[\"copy\",[30,0,[\"copyResponse\"]],\"discourse_ai.ai_bot.debug_ai_modal.copy_response\"]],null],[1,\"\\n        \"],[10,1],[14,0,\"ai-debut-modal__just-copied\"],[12],[1,[30,0,[\"justCopiedText\"]]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/debug-ai-modal.js",
      "scope": () => [_dModal.default, _i18n.default, _dButton.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = DebugAiModal;
});